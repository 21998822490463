



























import { Component, Vue, Prop } from 'vue-property-decorator';
import { RouterOptions } from 'vue-router';

@Component({
  name: 'BaseMenuItem',
})
export default class BaseMenuItem extends Vue {
  @Prop() readonly icon!: string;

  @Prop() readonly title!: string;

  @Prop() readonly to!: RouterOptions
}
















































import BaseMenuItem from '@/components/BaseMenuItem.vue';
// eslint-disable-next-line import/no-cycle
import LunchGameMixin from '@/mixins/luanchGameMixin';
import { Mixins, Component } from 'vue-mixin-decorator';

@Component({
  components: {
    BaseMenuItem,
  },
})
export default class Dashboard extends Mixins<LunchGameMixin>(LunchGameMixin) {
  // eslint-disable-next-line class-methods-use-this
  get menus(): Array<{ icon: string; title: string; to: string; }> {
    return [
      { icon: 'far fa-user', title: 'ข้อมูลสมาชิก', to: 'PlayerInfo' },
      { icon: 'fas fa-wallet', title: 'ฝากเงิน', to: 'Deposit' },
      { icon: 'fas fa-money-bill', title: 'ถอนเงิน', to: 'Withdraw' },
      { icon: 'fas fa-history', title: 'ประวัติธุรกรรม', to: 'Transaction' },
      { icon: 'fas fa-gift', title: 'รับโปรโมชั่น', to: 'JoinPromotion' },
      { icon: 'fas fa-users', title: 'ระบบแนะนำ', to: 'Affiliate' },
      { icon: 'fas fa-redo', title: 'รับยอดเสีย', to: 'Cashback' },
      { icon: 'fas fa-dharmachakra', title: 'กงล้อนำโชค', to: 'FortuneWheel' },
    ];
  }

  public gameUrl: string = '';

  async playGame() {
    const data = await this.luanchGame();
    if (data) {
      window.location.href = data;
    }
  }
}
